import { useRequest } from 'ahooks'

import { AuthAPI } from '$api/auth'

import { TokenDetail } from '$store/session/tokens'

export const useTokenLogout = () => {
  return useRequest(
    (token: TokenDetail['token']) => {
      const { logout, errorStatusResolver } = new AuthAPI(token)

      const request = async () => {
        try {
          await logout()
        } catch (err) {
          const errorCode = errorStatusResolver(err)

          // Assume certain error codes as "success" request
          if ([401].includes(errorCode)) {
            return
          }

          return Promise.reject(err)
        }
      }

      return request()
    },
    { manual: true }
  )
}

export default useTokenLogout
