export const SOCIAL_ICONS = [
  'phone',
  'email',
  'linkedin',
  'facebook',
  'org',
  'instagram',
  'twitter',
  'youtube',
  'whatsapp',
  'discord',
  'telegram',
  'line',
  'github',
  'signal'
] as const

export type SocialIcons = (typeof SOCIAL_ICONS)[number]
