import { Required } from 'utility-types'
import { jwtDecode } from 'jwt-decode'
import { batch, computed, effect, signal } from '@preact/signals-core'

import { TypeID } from '@genie-fintech/ui/types'

import { AuthAPI } from '$api/auth'
import LocalStorage from '$store/localStorage'
import { currentTokenDetail, updateCurrent } from '.'

import { NAMESPACE } from './constants'

const storage = new LocalStorage([NAMESPACE, 'tokens'])

const defaultTokensValue = () => storage.get<Token[]>([])

const applyDefaultStateValue = (
  states: Required<Partial<TokenState>, 'id'>
) => ({
  checked: false,
  loading: false,
  alive: false,
  ...states
})

type DecodedToken = {
  sub: TypeID
}

type NewState = Partial<Omit<TokenState, 'id'>>

/**
 * Type: JWT
 */
export type Token = string

export type TokenDetail = {
  token: Token

  /**
   * Decoded `sub` from `token`
   */
  id: DecodedToken['sub']
}

export type TokenState = Pick<TokenDetail, 'id'> & {
  /**
   * Indicate whether `token` validatity is checked
   */
  checked: boolean

  loading: boolean

  /**
   * `token` validatity
   */
  alive: boolean
}

export type TokenDetailWithState = TokenDetail & {
  state?: Omit<TokenState, 'id'>
}

/**
 * Type: Store
 */
export const tokens = signal<Token[]>(defaultTokensValue())

/**
 * Type: Store
 */
export const states = signal<TokenState[]>([])

/**
 * Type: Readonly
 */
export const details = computed<TokenDetail[]>(() =>
  tokens.value.map(token => ({
    id: jwtDecode<DecodedToken>(token).sub || token,
    token
  }))
)

/**
 * Type: Readonly
 */
export const all_ids = computed(() => details.value.map(({ id }) => id))

/**
 * Type: Readonly
 */
export const inactive_states = computed(() =>
  states.value.filter(({ alive, checked }) => checked && !alive)
)

/**
 * Type: Readonly
 */
export const with_state = computed<TokenDetailWithState[]>(() =>
  details.value.map(({ id, ...rest }) => ({
    id,
    state: states.value.find(({ id: sID }) => sID == id),
    ...rest
  }))
)

export const addToken = (token: Token) => {
  const id = jwtDecode<DecodedToken>(token).sub

  // Update the old token, if same `sub`
  const updatedTokens = [
    ...tokens.peek().filter(eToken => {
      const old_id = jwtDecode<DecodedToken>(eToken).sub

      return id !== old_id
    }),
    token
  ]

  // Reset old state upon replace
  if (getState(id)) {
    addOrUpdateState(id, { checked: false, alive: false })
  }

  tokens.value = updatedTokens

  return id
}

export const removeTokens = (ids: TokenState['id'][]) => {
  const allIDs = all_ids.peek()
  const activeSession = currentTokenDetail.peek()

  const indexes = ids
    .map(value => allIDs.findIndex(i => i === value))
    .filter(v => v !== -1)

  const newTokens = tokens.peek().filter((_, index) => !indexes.includes(index))
  const newStates = states.peek().filter(({ id }) => !ids.includes(id))

  batch(() => {
    tokens.value = newTokens
    states.value = newStates

    if (activeSession && allIDs.includes(activeSession?.id)) {
      updateCurrent(undefined)
    }
  })
}

export const excludeStates = (id: TokenState['id']) => {
  return states.peek().filter(state => state.id !== id)
}

export const getState = (id: TokenState['id']) => {
  return states.peek().find(state => state.id === id)
}

export const removeState = (id: TokenState['id']) => {
  states.value = excludeStates(id)
}

export const addOrUpdateState = (id: TokenState['id'], newState: NewState) => {
  const statesValue = states.peek()

  const state = statesValue.find(s => s.id === id)

  // Update
  if (state) {
    states.value = statesValue.map(state => ({
      ...state,
      ...(state.id === id && newState)
    }))

    return
  }

  // Add
  states.value = [
    ...statesValue,
    applyDefaultStateValue({
      id,
      ...newState
    })
  ]
}

export const refreshStates = (tokens: TokenDetail[], force = false) => {
  tokens.map(async ({ id, token }) => {
    const api = new AuthAPI(token)

    const state = getState(id)

    // Skip when in-progress
    if (state?.loading) return

    // Check only once
    if (!force && state?.checked) return

    try {
      addOrUpdateState(id, { loading: true })

      await api.check()

      addOrUpdateState(id, { loading: false, checked: true, alive: true })
    } catch {
      addOrUpdateState(id, { loading: false, checked: true, alive: false })
    }
  })
}

/**
 * Type: Effect
 * Sync to LocalStorage
 */
effect(() => storage.set(tokens.value))

/**
 * Type: Effect
 * Remove old states
 */
effect(() => {
  const idValues = all_ids.value
  const stateValues = states.peek()

  const outdatedIDs = stateValues
    .map(({ id }) => id)
    .filter(id => !idValues.includes(id))

  outdatedIDs.map(removeState)
})

/**
 * Type: Effect
 * Refresh `states`
 */
effect(() => {
  refreshStates(details.value)
})
