import { FC } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Password } from '@genie-fintech/ui/components/hook-fields'

import { TypeSubmitForm } from '$hook-forms/types'
import { requiredString } from '$hook-forms/general-validators'

import { submit } from '$forms/common/style.css'

import { container } from './styles.css'

const Schema = z
  .object({
    confirm_password: requiredString,
    password: requiredString
  })
  .refine(({ confirm_password, password }) => password === confirm_password, {
    message: "Password doesn't match",
    path: ['confirm_password']
  })
const resolver = zodResolver(Schema)

type TFields = z.infer<typeof Schema>

const defaultValues: TFields = {
  confirm_password: '',
  password: ''
}

export type ResetPasswordFormFields = TFields

export type ResetPasswordFormProps = TypeSubmitForm<TFields>

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  onSuccess,
  disabled
}) => {
  const { control, reset, handleSubmit, formState } = useForm<TFields>({
    resolver,
    defaultValues,
    mode: 'all'
  })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  return (
    <form
      className={container}
      onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
      noValidate
      autoComplete="off"
    >
      <Password
        control={control}
        name="password"
        label="New Password"
        disabled={disabled}
      />
      <Password
        control={control}
        name="confirm_password"
        label="Confirm password"
        disabled={disabled}
      />

      <button type="submit" className={submit} disabled={isInvalid}>
        Reset
      </button>
    </form>
  )
}

export default ResetPasswordForm
