import { FC, ReactNode } from 'react'

import { copyrightText } from '$constants/texts'

import { container } from './styles.css'

export type CopyrightTextProps = { text?: ReactNode }

export const CopyrightText: FC<CopyrightTextProps> = ({
  text = <p dangerouslySetInnerHTML={{ __html: copyrightText }} />
}) => <div className={container}>{text}</div>

export default CopyrightText
