import { FC } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import Phone from '$hook-fields/Phone'

import { TypeSubmitForm } from '$hook-forms/types'
import { requiredString } from '$hook-forms/general-validators'

import { submit } from '$forms/common/style.css'
import { callingCodes } from '$constants/country-data'

import { container } from './styles.css'

const Schema = z.object({
  phone_code: requiredString,
  phone_no: requiredString.min(5, { message: 'At least 5 digits required' })
})

const resolver = zodResolver(Schema)

type TFields = z.infer<typeof Schema>

const defaultValues: TFields = {
  phone_code: `${callingCodes[0].code}`,
  phone_no: ''
}

export type MobileOTPRequestFormFields = TFields

export type MobileOTPRequestFormProps = TypeSubmitForm<TFields>

export const MobileOTPRequestForm: FC<MobileOTPRequestFormProps> = ({
  onSuccess,
  disabled
}) => {
  const { control, reset, handleSubmit, formState } = useForm<TFields>({
    resolver,
    defaultValues,
    mode: 'all'
  })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  return (
    <form
      className={container}
      onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
      noValidate
      autoComplete="off"
    >
      <Phone
        control={control}
        callingCodes={callingCodes}
        name="phone_no"
        name2="phone_code"
        label="Phone Number"
        required
        inputProps={{ placeholder: 'XXXX XXXX XXXX' }}
        disabled={disabled}
      />

      <button type="submit" className={submit} disabled={isInvalid}>
        Next
      </button>
    </form>
  )
}

export default MobileOTPRequestForm
