import { FC } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types/jsx'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

import { Avatar } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'

import {
  container,
  nav,
  name as nameStyle,
  username as usernameStyle,
  aside,
  icon
} from './style.css'

export type AccountPreviewProps = {
  name: string
  image?: string
  username?: string
  containerProps?: TagsNoRef<'article'>
}

export const AccountPreview: FC<AccountPreviewProps> = ({
  name,
  image,
  username,
  containerProps
}) => (
  <article {...propsWithClassNames(containerProps, container)}>
    <Avatar image={{ src: image }} />

    <nav className={nav}>
      <h4 className={nameStyle}>{name}</h4>

      <p className={usernameStyle}>{username}</p>
    </nav>

    <aside className={aside}>
      <Icon namespace="Forward" className={icon} />
    </aside>
  </article>
)

export default AccountPreview
