import { SVGProps, FC } from 'react'

type AuthBackgroundProps = SVGProps<SVGSVGElement> & {
  color?: string
}

export const AuthBackground: FC<AuthBackgroundProps> = ({
  color = 'white',
  ...props
}) => (
  <svg
    width={438}
    height={783}
    viewBox="0 0 438 783"
    fill="none"
    preserveAspectRatio="xMidYMid slice"
    {...props}
  >
    <g clipPath="url(#clip0_816_597)">
      <circle opacity={0.2} cx="179.5" cy="737.5" r="280.5" fill={color} />
      <circle opacity={0.2} cx="555.5" cy="-49.5" r="280.5" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_816_597">
        <rect width={438} height={783} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default AuthBackground
