import { FC, useRef } from 'react'
import { useBoolean, useClickAway, useRequest } from 'ahooks'

import { Portal } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'

import useNoScrollY from '$browser/useNoScrollY'

import UpdatePasswordForm, {
  UpdatePasswordFormProps
} from '$hook-forms/UpdatePasswordForm'

import lazyToast from '$services/lazyToast'
import { UpdatePasswordRequest } from '$services/api/auth'
import api from '$model/api'

import { closeButton, h2, overlay, overlayContent, header } from './styles.css'
import { neutralButton } from '../styles.css'

const { errorMessageResolver } = api.value.auth

const request = (data: UpdatePasswordRequest) => {
  const { updatePassword } = api.value.auth

  return updatePassword(data)
}

export const ChangePasswordButton: FC = () => {
  const overlayContentRef = useRef(null)
  const buttonRef = useRef(null)

  const [open, { toggle, setFalse }] = useBoolean()

  const { loading, runAsync } = useRequest(request, { manual: true })

  useNoScrollY('profile-password-change', open)
  useClickAway(setFalse, [overlayContentRef, buttonRef])

  const onSuccess: UpdatePasswordFormProps['onSuccess'] = (reset, data) => {
    lazyToast(runAsync(data), {
      loading: 'Updating..',
      error: errorMessageResolver,
      success: 'Password update successful.'
    }).then(() => {
      reset()
      setFalse()
    })
  }

  return (
    <>
      <Portal>
        <section className={overlay({ open })}>
          <article ref={overlayContentRef} className={overlayContent}>
            <header className={header}>
              <h2 className={h2}>Update password</h2>
              <button onClick={toggle} className={closeButton} type="button">
                <Icon namespace="Cross" />
              </button>
            </header>

            <main>
              <UpdatePasswordForm onSuccess={onSuccess} disabled={loading} />
            </main>
          </article>
        </section>
      </Portal>

      <button
        ref={buttonRef}
        type="button"
        onClick={toggle}
        className={neutralButton}
      >
        Change password
      </button>
    </>
  )
}

export default ChangePasswordButton
