import { FC } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { BaseText } from '@genie-fintech/ui/components/hook-fields'

import { loginFormPlaceholderText } from '$constants/texts'
import { TypeSubmitForm } from '$hook-forms/types'
import { requiredString } from '$hook-forms/general-validators'

import { submit } from '$forms/common/style.css'

import { container } from './styles.css'

const Schema = z.object({
  email: requiredString.email()
})
const resolver = zodResolver(Schema)

type TFields = z.infer<typeof Schema>

const defaultValues: TFields = {
  email: ''
}

export type ForgotPasswordFormFields = TFields

export type ForgotPasswordFormProps = TypeSubmitForm<TFields>

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  onSuccess,
  disabled
}) => {
  const { control, reset, handleSubmit, formState } = useForm<TFields>({
    resolver,
    defaultValues,
    mode: 'all'
  })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  return (
    <form
      className={container}
      onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
      noValidate
      autoComplete="off"
    >
      <BaseText
        control={control}
        name="email"
        label="Email"
        inputProps={{ placeholder: loginFormPlaceholderText.email }}
        disabled={disabled}
      />

      <button type="submit" className={submit} disabled={isInvalid}>
        Send
      </button>
    </form>
  )
}

export default ForgotPasswordForm
