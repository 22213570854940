import { computed, signal } from '@preact/signals-core'

import { isAllTrue } from '$app/utilities'

import { hasActiveSource } from '$model/api'

import { with_state as tokenStates } from './session/tokens'
import { currentTokenState } from './session'
import { cached } from './queryParams'

/**
 * Type: Store
 */
const apiPrepared = signal(false)

/**
 * Type: Readonly
 */
export const status = computed(() => {
  const isApiPrepared = apiPrepared.value
  const hasApiActiveSource = hasActiveSource.value

  const isActiveSessionAlive = !!currentTokenState.value?.alive
  const hasActiveSession = !!currentTokenState.value
  const hasSession = !!tokenStates.value.length

  const isQueryParamsCached = cached.value

  const tokensChecked = isAllTrue(
    tokenStates.value.map(({ state }) => !!state?.checked)
  )

  const tokensLoading = !!tokenStates.value
    .map(({ state }) => state?.loading)
    .filter(Boolean).length

  const all = isAllTrue([
    isApiPrepared,
    tokensChecked,
    tokensLoading,
    hasApiActiveSource,
    hasSession,
    hasActiveSession,
    isActiveSessionAlive,
    isQueryParamsCached
  ])

  return {
    all,
    isApiPrepared,
    tokensChecked,
    tokensLoading,
    hasApiActiveSource,
    hasSession,
    hasActiveSession,
    isActiveSessionAlive,
    isQueryParamsCached
  }
})

export const setApiPrepared = (status: boolean) => {
  apiPrepared.value = status
}
