import { FC, useId, useImperativeHandle, useRef } from 'react'
import { Simplify } from 'type-fest'
import { useBoolean } from 'ahooks'

import { TagsNoRef, ToReferences } from '../../../types'
import {
  CommonElementControlProps,
  CommonFieldProps,
  CommonTextFieldProps,
  CommonElementProps,
  CommonFieldStateProps,
  CommonWithAffixProps
} from '../common/types'

import {
  propsWithClassNames,
  toClassNames,
  trueOrUndefined
} from '../../../functions'

import {
  field,
  requiredIndicator,
  label as labelStyle,
  message as messageStyle,
  description as descriptionStyle,
  element,
  elementControl,
  fieldModes,
  AffixControl,
  affixControl
} from '../common/style.css'

import { container, input } from './style.css'

type VNodes = {
  labelRef: HTMLLabelElement
  containerRef: HTMLDivElement
  elementControlRef: HTMLDivElement
  inputRef: HTMLInputElement
}

type References = Partial<ToReferences<VNodes>>

type EleCtrlTagProps = TagsNoRef<'div'>
type InputTagProps = TagsNoRef<'input'>

type ElementControlReferences = Pick<
  References,
  'inputRef' | 'elementControlRef'
>
type ElementControlProps = CommonElementControlProps &
  CommonWithAffixProps &
  CommonElementProps &
  CommonTextFieldProps &
  ElementControlReferences & {
    elementControlProps?: EleCtrlTagProps
    inputProps?: InputTagProps
  }

const ElementControl: FC<ElementControlProps> = ({
  inputProps,
  elementControlProps,
  placeholder,
  id,
  disabled,
  error,
  success,
  affix,
  inputRef,
  elementControlRef
}) => {
  const inputNode = useRef<VNodes['inputRef']>(null)

  const { post: suffix, pre: prefix } = { ...affix }
  const {
    onFocus: onFocusNative,
    onBlur: onBlurNative,
    className,
    ...restInputTagProps
  } = { ...inputProps }
  const { onClick: controlNativeOnclick, ...restElementControlProps } = {
    ...elementControlProps
  }

  const [isFocus, { setFalse, setTrue }] = useBoolean()

  const stateProps: CommonFieldStateProps = {
    'data-success': trueOrUndefined(success),
    'data-error': trueOrUndefined(error),
    'data-disabled': trueOrUndefined(disabled),
    'data-focus': trueOrUndefined(isFocus)
  }

  const position: AffixControl['position'] = (() => {
    const hasPrefix = !!prefix
    const hasSuffix = !!suffix

    if (hasPrefix && hasSuffix) {
      return 'both'
    } else if (hasPrefix) {
      return 'pre'
    } else if (hasSuffix) {
      return 'post'
    }
  })()

  const inputClassName = toClassNames(input, element, className)
  const controlClassName = toClassNames(
    elementControl,
    affixControl({ position })
  )

  // Auto-focus input upon wrapper click
  const controlOnClick: EleCtrlTagProps['onClick'] = (...args) => {
    inputNode.current?.focus()
    controlNativeOnclick?.(...args)
  }

  const onFocus: InputTagProps['onFocus'] = (...args) => {
    setTrue()
    onFocusNative?.(...args)
  }

  const onBlur: InputTagProps['onBlur'] = (...args) => {
    setFalse()
    onBlurNative?.(...args)
  }

  useImperativeHandle(inputRef, () => inputNode.current as never)

  return (
    <div
      ref={elementControlRef}
      className={controlClassName}
      onClick={controlOnClick}
      {...stateProps}
      {...restElementControlProps}
    >
      {prefix}
      <input
        ref={inputNode}
        type="text"
        className={inputClassName}
        {...stateProps}
        {...{ id, placeholder, onFocus, onBlur, disabled }}
        {...restInputTagProps}
      />
      {suffix}
    </div>
  )
}

type NoPrivateProps = Omit<ElementControlProps, keyof CommonElementControlProps>

export type BaseTextProps = Simplify<
  CommonFieldProps & NoPrivateProps & References
>

export const BaseText: FC<BaseTextProps> = ({
  fullWidth,
  error,
  success,
  label,
  required,
  disabled,
  containerProps,
  containerRef,
  labelRef,
  message,
  description,
  ...restElementControlProps
}) => {
  const id = useId()

  const hasLabel = !!label

  const stateProps: CommonFieldStateProps = {
    'data-success': trueOrUndefined(success),
    'data-error': trueOrUndefined(error),
    'data-disabled': trueOrUndefined(disabled)
  }

  const elementControlProps: ElementControlProps = {
    id,
    error,
    success,
    disabled,
    ...restElementControlProps
  }

  const containerFinalProps = propsWithClassNames(
    containerProps,
    field,
    fieldModes({ fullWidth }),
    container
  )

  return (
    <div ref={containerRef} {...stateProps} {...containerFinalProps}>
      {hasLabel && (
        <label ref={labelRef} htmlFor={id} className={labelStyle}>
          {label}
          {required && <span className={requiredIndicator}>*</span>}
        </label>
      )}

      <ElementControl {...elementControlProps} />

      {description && (
        <div className={descriptionStyle} children={description} />
      )}

      {message && <div className={messageStyle} children={message} />}
    </div>
  )
}

export default BaseText
