import { signal, computed } from '@preact/signals-core'

import {
  TokenDetailWithState,
  TokenDetail,
  details,
  with_state
} from './tokens'

export { addToken } from './tokens'

type Session = {
  /**
   * @see {@link TokenDetail}
   */
  tokenDetails: TokenDetail[]

  /**
   * @see {@link TokenDetailWithState}
   */
  tokenStates: TokenDetailWithState[]

  /**
   * Current Session ID
   */
  current?: TokenDetailWithState['id']

  /**
   * Current Session Detail
   *
   * @see {@link TokenDetail}
   */
  currentTokenDetail?: TokenDetail

  /**
   * Current Session State
   *
   * @see {@link TokenDetailWithState}
   */
  currentTokenState?: TokenDetailWithState['state']
}

type Current = Session['current']

/**
 * Type: Store
 */
export const current = signal<Current>(undefined)

/**
 * Type: Readonly
 */
export const currentTokenState = computed(
  () => with_state.value.find(({ id }) => id == current.value)?.state
)

/**
 * Type: Readonly
 */
export const currentTokenDetail = computed(() =>
  details.value.find(({ id }) => id == current.value)
)

/**
 * Type: Readonly
 */
export const session = computed<Session>(() => ({
  tokenDetails: details.value,
  tokenStates: with_state.value,
  current: current.value,
  currentTokenDetail: currentTokenDetail.value,
  currentTokenState: currentTokenState.value
}))

export const updateCurrent = (id?: Current) => (current.value = id)

export default session
