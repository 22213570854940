import { FC, PropsWithChildren } from 'react'

import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'

import CopyrightText from '$elements/CopyrightText'

import AuthLogo from '$icons/AuthLogo'
import AuthBackground from '$icons/AuthBackground'

import useLayoutClass from '$browser/useLayoutClass'

import { authLayoutDescriptionText } from '$constants/texts'

import {
  aside,
  container,
  content,
  footer,
  inner,
  main,
  asideNav,
  asideContent,
  asideBackground,
  asideContentTitle,
  asideContentText,
  logo
} from './styles.css'

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  useLayoutClass({ name: 'AuthLayout' })

  const ready = useIsMounted()

  return (
    <section className={container} data-ready={trueOrUndefined(ready)}>
      <div className={inner}>
        <aside className={aside}>
          <nav className={asideNav}>
            <AuthLogo className={logo} />
          </nav>

          <article className={asideContent}>
            <h3 className={asideContentTitle}>Welcome back !</h3>

            <div className={asideContentText}>
              <p>{authLayoutDescriptionText}</p>
            </div>
          </article>

          <AuthBackground className={asideBackground} />
        </aside>

        <main className={main}>
          <div />

          <article className={content}>{children}</article>

          <footer className={footer}>
            <CopyrightText />
          </footer>
        </main>
      </div>
    </section>
  )
}

export default AuthLayout
