import { assignInlineVars } from '@vanilla-extract/dynamic'
import toast from 'react-hot-toast'
import { FC, useRef } from 'react'
import { useRequest, useSize } from 'ahooks'

import { Spinner } from '@genie-fintech/ui/components'

import useSignal from '$actions/useSignal'
import useActiveProfileLogout from '$actions/useActiveProfileLogout'
import usePageClass from '$browser/usePageClass'
import useIsMounted from '$browser/useIsMounted'

import session from '$store/session'

import SocialIcon from '$icons/SocialIcon'

import { CommonAPI } from '$api/common'
import { api } from '$model/api'

import ChangePasswordButton from './ChangePasswordButton'

import {
  container,
  content,
  header,
  photo,
  name as nameStyle,
  info,
  main,
  card,
  footer,
  loader,
  vars,
  neutralButton
} from './styles.css'

const { errorMessageResolver } = new CommonAPI()

const request = () => {
  const { profile, successResolver } = api.value.auth

  return profile().then(successResolver)
}

const pageName = 'ProfileDetail'

export const ProfileDetail: FC = () => {
  usePageClass({ name: pageName })

  const isMounted = useIsMounted()

  const { current } = useSignal(session)

  const logoutRequest = useActiveProfileLogout()
  const detailRequest = useRequest(request, { refreshDeps: [current] })

  const isEmpty = !!detailRequest.data == false
  const isLoading = detailRequest.loading || !isMounted

  const { name, address, profile_photo_url, email, phone, id } = {
    ...detailRequest.data?.data
  }

  // Styles
  const figureRef = useRef<HTMLElement>(null)
  const figureSize = useSize(figureRef)
  const photoShift = `${(figureSize?.height || 0) / 2}px`

  const doLogout = () => {
    toast.promise(logoutRequest.logout(), {
      loading: 'Clearing session..',
      error: errorMessageResolver,
      success: 'Logout success.'
    })
  }

  return (
    <section className={container({ isLoading })}>
      <div className={loader}>
        <Spinner color="absolute.light" />
      </div>

      <article
        className={content}
        style={assignInlineVars({
          [vars.photoShift]: photoShift
        })}
      >
        {isEmpty ? (
          'Failed to retreive profile'
        ) : (
          <>
            <figure ref={figureRef} className={photo}>
              {profile_photo_url && (
                <div>
                  <img src={profile_photo_url} alt={name} />
                  <div />
                </div>
              )}
            </figure>

            <header className={header}>
              <h1 className={nameStyle}>{name}</h1>

              {id && (
                <div className={info}>
                  <label>Account ID</label>
                  <div className="trimmed">{id}</div>
                </div>
              )}

              {address && (
                <div className={info}>
                  <label>Address</label>
                  <div>{address}</div>
                </div>
              )}
            </header>

            <main className={main}>
              {email && (
                <article className={card}>
                  <SocialIcon name="email" />

                  <div>
                    <label>Email</label>
                    <div className="value">{email}</div>
                  </div>
                </article>
              )}

              {phone && (
                <article className={card}>
                  <SocialIcon name="phone" />

                  <div>
                    <label>Phone</label>
                    <div className="value">{phone}</div>
                  </div>
                </article>
              )}
            </main>

            <footer className={footer}>
              <ChangePasswordButton />

              <button
                type="button"
                className={neutralButton}
                disabled={logoutRequest.loading}
                onClick={doLogout}
              >
                Logout
              </button>
            </footer>
          </>
        )}
      </article>
    </section>
  )
}

export default ProfileDetail
