import { FC, useRef } from 'react'
import { useBoolean, useClickAway } from 'ahooks'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { Icon } from '@genie-fintech/ui/icons'
import { Spinner } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { TagsNoRef } from '@genie-fintech/ui/types'

import AccountPreview, { AccountPreviewProps } from '$elements/AccountPreview'
import Gear from '$icons/Gear'

import useSignal from '$actions/useSignal'
import useTokenProfile from '$actions/useTokenProfile'
import useTokenProfileLogout from '$actions/useTokenProfileLogout'

import session from '$store/session'
import { TokenDetail } from '$store/session/tokens'

import {
  header,
  title as titleStyle,
  container,
  main,
  list,
  loaderControl,
  footer,
  addNew,
  manage,
  nav,
  options,
  previewMain,
  optionLogout,
  previewContainer,
  manageIcon,
  addNewIcon,
  optionIcon,
  navInner
} from './styles.css'

export type AccountSelectionProps = {
  title?: string
  onSelect: (id: TokenDetail['id']) => void
  onAddNew: VoidFunction
}

export const AccountSelection: FC<AccountSelectionProps> = ({
  title = 'Choose an account',
  onSelect,
  onAddNew
}) => {
  const [manageRef] = useAutoAnimate()
  const navRef = useRef(null)
  const listRef = useRef(null)

  const [isManageable, { toggle, setFalse }] = useBoolean()
  const { tokenDetails } = useSignal(session)

  const hasTokens = !!tokenDetails.length

  const manageProps: TagsNoRef<'button'> = {
    title: 'Logout or Remove account',
    type: 'button',
    className: manage({ active: isManageable })
  }

  useClickAway(setFalse, [listRef, navRef])

  return (
    <section className={container}>
      <header className={header}>
        <h2 className={titleStyle}>{title}</h2>
      </header>

      <nav className={nav} ref={navRef}>
        <div className={navInner} ref={manageRef}>
          {!isManageable && (
            <button {...manageProps} onClick={toggle}>
              <Gear className={manageIcon} />
              <span>Manage Session{tokenDetails.length > 1 && 's'}</span>
            </button>
          )}

          {isManageable && (
            <button {...manageProps} onClick={toggle}>
              <Gear className={manageIcon} />
              <span>Cancel</span>
            </button>
          )}
        </div>
      </nav>

      {hasTokens && (
        <main className={main}>
          <div className="sticky fade-out" />

          <div className={list} ref={listRef}>
            {tokenDetails.map(detail => (
              <Preview
                key={detail.id}
                isManageable={isManageable}
                containerProps={{
                  onClick: () => onSelect(detail.id)
                }}
                {...detail}
              />
            ))}
          </div>

          <div className="sticky fade-in" />
        </main>
      )}

      <footer className={footer}>
        <button
          className={addNew}
          onClick={onAddNew}
          type="button"
          aria-label="use another account"
        >
          <span>Login to another account</span>
          <Icon namespace="Forward" className={addNewIcon} />
        </button>
      </footer>
    </section>
  )
}

type PreviewProps = Pick<TokenDetail, 'token'> &
  Pick<AccountPreviewProps, 'containerProps'> & {
    isManageable: boolean
  }

export const Preview: FC<PreviewProps> = ({
  token,
  containerProps,
  isManageable
}) => {
  const [contRef] = useAutoAnimate()

  const { data: profile, loading: profileLoading } = useTokenProfile(token)
  const { loading: logoutLoading, logout } = useTokenProfileLogout(
    token,
    profile
  )

  return (
    <article ref={contRef} className={previewContainer}>
      {profileLoading && (
        <div className={loaderControl}>
          <Spinner />
        </div>
      )}

      {profile && (
        <main className={previewMain({ inactivePreview: isManageable })}>
          <AccountPreview
            name={profile.name}
            username={profile.email}
            image={profile.profile_photo_url}
            containerProps={{
              ...containerProps,
              onClick: profileLoading ? undefined : containerProps?.onClick,
              'data-disabled': trueOrUndefined(isManageable),
              'data-account-id': profile.id,
              role: 'button'
            }}
          />

          <aside className={options({ active: isManageable })}>
            <button
              type="button"
              className={optionLogout({ loading: logoutLoading })}
              title="Logout this session"
              disabled={logoutLoading}
              onClick={logout}
            >
              {logoutLoading ? (
                <Spinner color="inherit" className={optionIcon} />
              ) : (
                <Icon namespace="Trash" className={optionIcon} />
              )}
              <span>{logoutLoading ? 'Removing...' : 'Remove'}</span>
            </button>
          </aside>
        </main>
      )}
    </article>
  )
}

export default AccountSelection
