import { useRequest } from 'ahooks'

import { AuthAPI } from '$api/auth'
import { TokenDetail } from '$store/session/tokens'

export const useTokenProfile = (token: TokenDetail['token']) => {
  return useRequest(() => {
    const api = new AuthAPI(token)

    return api
      .profile()
      .then(api.successResolver)
      .then(({ data }) => data)
  })
}

export default useTokenProfile
