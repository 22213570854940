import { LOCAL_STORAGE_KEY_SEPARATOR, NAMESPACE } from '$constants'

export class LocalStorage {
  private key = NAMESPACE

  constructor(names: string[]) {
    this.key = [NAMESPACE, ...names].join(LOCAL_STORAGE_KEY_SEPARATOR)
  }

  public get = <T extends object>(defaultValue: T): T => {
    const data = localStorage.getItem(this.key)

    if (!data) return defaultValue

    return JSON.parse(data) as T
  }

  public set = <T extends object>(value: T) => {
    localStorage.setItem(this.key, JSON.stringify(value))
  }
}

export default LocalStorage
