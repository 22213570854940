import { ID } from '../constants'
import CreateAtom from '../reactivity/atom'

const storageKey = `${ID}:dark-appearance`
const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)'

const matchMedia = self.matchMedia(COLOR_SCHEME_QUERY)

const matchQuery = () => matchMedia.matches ?? false

const getStatus = () => {
  const storedValue = localStorage.getItem(storageKey)
  const hasValue = storedValue !== null

  const value = storedValue == 'true'

  return { hasValue, value }
}

export const autoAtom = new CreateAtom(!getStatus().hasValue)
export const darkModeAtom = new CreateAtom(
  (() => {
    const { hasValue, value } = getStatus()

    if (hasValue) return value

    return matchQuery()
  })()
)

const enableAuto = () => {
  autoAtom.update(true)
}
const disableAuto = () => {
  autoAtom.update(false)
}

const darkMode = (...args: Parameters<typeof darkModeAtom.update>) => {
  disableAuto()
  darkModeAtom.update(...args)
}

export const toggle = () => darkMode(prev => !prev)
export const enable = () => darkMode(true)
export const disable = () => darkMode(false)
export const auto = () => {
  darkMode(matchQuery())
  enableAuto()
}

const initCycleCount = 1
let cycleCount = initCycleCount

export const rotate = () => {
  if (cycleCount >= 3) {
    auto()
    cycleCount = initCycleCount

    return
  }

  toggle()

  cycleCount++
}

// Delete cache when user prefers auto mode
autoAtom.subscribe(value => {
  if (!value) return
  localStorage.removeItem(storageKey)
})

// Cache as user's selection
darkModeAtom.subscribe(value => {
  localStorage.setItem(storageKey, `${value}`)
})

// Update darkMode if os prefers changes
matchMedia.addEventListener('change', auto)
