import { useEffect, useState } from 'react'
import { Signal, ReadonlySignal, effect } from '@preact/signals-core'

export const useSignal = <T extends Signal | ReadonlySignal>(signal: T) => {
  const [state, setState] = useState<T['value']>(() => signal.value)

  useEffect(() => {
    const cleanup = effect(() => {
      setState(signal.value)
    })

    return cleanup
  }, [signal])

  return state
}

export default useSignal
