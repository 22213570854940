import { FC } from 'react'

import { Icon } from '@genie-fintech/ui/icons'

import usePageClass from '$browser/usePageClass'
import useRouter from '$actions/useRouter'

import Link from '$elements/Link'
import ResetPasswordBlock from '$blocks/ResetPasswordBlock'

import { invalidContent, invalidText, tryAgainLink } from './styles.css'

const pageName = 'ResetPassword'

export const ResetPassword: FC = () => {
  usePageClass({ name: pageName })

  const { queryParams } = useRouter()
  const { email, token } = queryParams

  const formProps = [email, token]

  const isValid = formProps.filter(Boolean).length == formProps.length

  if (!isValid) {
    return (
      <div className={invalidContent}>
        <p className={invalidText}>
          <Icon namespace="Warning" /> Your password link has been expired.
          Please{' '}
          <Link to="authForgotPassword" className={tryAgainLink}>
            reset again
          </Link>
          .
        </p>
      </div>
    )
  }

  return <ResetPasswordBlock {...{ email, token }} />
}

export default ResetPassword
