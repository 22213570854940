import { FC, PropsWithChildren, useEffect } from 'react'

import { Spinner } from '@genie-fintech/ui/components'

import useRouter from '$actions/useRouter'
import useSignal from '$actions/useSignal'

import { status } from '$store/status'

/**
 * Redirects to `authAccounts` when session is inactive. Otherwise, render the children.
 */
export const AccountSelectionGuard: FC<PropsWithChildren> = ({ children }) => {
  const { redirectWithReturnTo, name } = useRouter()

  const { hasApiActiveSource, hasActiveSession } = useSignal(status)

  const isAccountSelected = hasApiActiveSource && hasActiveSession

  useEffect(() => {
    if (!name) return

    if (!isAccountSelected) {
      redirectWithReturnTo(
        'authAccounts',
        name,
        // Keep query-params
        ({ queryParams }) => ({ queryParams })
      )

      return
    }
  }, [isAccountSelected, name, redirectWithReturnTo])

  if (!isAccountSelected) {
    return <Spinner style={{ marginLeft: 'auto', marginRight: 'auto' }} />
  }

  return children
}

export default AccountSelectionGuard
