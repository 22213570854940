import { FC } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Password } from '@genie-fintech/ui/components/hook-fields'

import { TypeSubmitForm } from '$hook-forms/types'
import { requiredString } from '$hook-forms/general-validators'

import { submit } from '$forms/common/style.css'

import { container } from './styles.css'

const Schema = z
  .object({
    current_password: requiredString,
    password: requiredString
  })
  .refine(({ current_password, password }) => password !== current_password, {
    message: 'Please use new password',
    path: ['password']
  })
const resolver = zodResolver(Schema)

type TFields = z.infer<typeof Schema>

const defaultValues: TFields = {
  current_password: '',
  password: ''
}

export type UpdatePasswordFormFields = TFields

export type UpdatePasswordFormProps = TypeSubmitForm<TFields>

export const UpdatePasswordForm: FC<UpdatePasswordFormProps> = ({
  onSuccess,
  disabled
}) => {
  const { control, reset, handleSubmit, formState } = useForm<TFields>({
    resolver,
    defaultValues,
    mode: 'all'
  })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  return (
    <form
      className={container}
      onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
      noValidate
      autoComplete="off"
    >
      <Password
        control={control}
        name="current_password"
        label="Current password"
        disabled={disabled}
      />
      <Password
        control={control}
        name="password"
        label="New Password"
        disabled={disabled}
      />

      <button type="submit" className={submit} disabled={isInvalid}>
        Update
      </button>
    </form>
  )
}

export default UpdatePasswordForm
