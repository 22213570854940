import { flatten } from 'lodash-es'
import { getCountryData, TCountryCode } from 'countries-list'

const allowedCountries = import.meta.env.VITE_ALLOWED_COUNTRIES

const countries = (() => {
  const raw = allowedCountries.split(',') as TCountryCode[]

  return raw.map(getCountryData)
})()

export type CallingCodes = typeof callingCodes
export const callingCodes = flatten(
  countries.map(({ phone, iso2, name }) =>
    phone.map(code => ({
      id: iso2,
      name,
      code
    }))
  )
)
