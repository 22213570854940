import { computed, signal } from '@preact/signals-core'

import { GetCodeRequest } from '$api/auth'
import { ExtendedQueryParams, routeState } from '$app/router'

type Params = Partial<GetCodeRequest & ExtendedQueryParams>

/**
 * Type: Store
 */
const params = signal<Params>(routeState.queryParams)

/**
 * Type: Store
 */
export const cached = signal(false)

/**
 * Cached OAuth query parameters from the initial request.
 *
 * Type: Read
 */
export const queryParams = computed(() => ({
  params: params.value,
  cached: cached.value
}))

export const update = (value: Params) => {
  params.value = { ...params.peek(), ...value }
}

export const setCached = () => {
  cached.value = true
}

export default queryParams
