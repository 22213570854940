import { FC } from 'react'

import { SocialIcons } from '../constants'
import { getSocialIcon } from '$icons/functions'

type SocialIconProps = {
  name: SocialIcons
}

export const SocialIcon: FC<SocialIconProps> = ({ name }) => (
  <svg width={24} height={24}>
    <use xlinkHref={`#${getSocialIcon(name)}`} />
  </svg>
)

export default SocialIcon
