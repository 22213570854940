import { useBoolean, useRequest } from 'ahooks'
import { FC, useEffect } from 'react'

import { Spinner } from '@genie-fintech/ui/components'

import usePageClass from '$browser/usePageClass'
import useSignal from '$actions/useSignal'

import { session } from '$store/session'
import { api } from '$model/api'

import {
  main,
  container,
  description,
  title,
  appLogo as logoStyle,
  appNameInitial,
  app as appStyle,
  appLogoCanvas,
  header,
  appTitle,
  appContent,
  appDescription,
  hr,
  appLogoElement,
  content,
  loader
} from './styles.css'

const pageName = 'Apps'

const request = () => {
  const { listPublicApps, successResolver } = api.value.auth

  return listPublicApps().then(successResolver)
}

export const Apps: FC = () => {
  usePageClass({ name: pageName })

  const { current: sessionID } = useSignal(session)

  // Track initial loading
  const [ready, { setTrue }] = useBoolean()

  const { data, runAsync, loading, error } = useRequest(request, {
    manual: true,
    refreshDeps: [sessionID]
  })
  const { data: list = [] } = { ...data }

  const formatted = list.map(({ id, ...rest }, key) => ({
    id: id || key,
    ...rest
  }))

  const isLoading = !ready || loading
  const hasError = !!error === true
  const isEmpty = formatted.length === 0 || hasError

  useEffect(() => {
    runAsync().finally(setTrue)
  }, [runAsync, setTrue])

  return (
    <section className={container({ isLoading })}>
      <div className={loader}>
        <Spinner />
      </div>

      <article className={content}>
        <header className={header}>
          {isEmpty ? (
            <h1 className={title}>Applications aren't available</h1>
          ) : (
            <>
              <h1 className={title}>Applications</h1>

              <hr className={hr} />

              <nav className={description}>
                <p>you'll be redirected to authorization screen</p>
              </nav>
            </>
          )}
        </header>

        {!isEmpty && (
          <main className={main}>
            {formatted.map(({ id, name, description = '', domain, logo }) => (
              <a
                key={id}
                className={appStyle}
                href={domain}
                title={[name, description].filter(Boolean).join('\n')}
              >
                <figure className={logoStyle}>
                  {logo ? (
                    <img className={appLogoElement} src={logo} alt={name} />
                  ) : (
                    <div className={appNameInitial}>
                      {name.charAt(0).toUpperCase()}
                      {name.charAt(1).toLocaleLowerCase()}
                    </div>
                  )}

                  <div className={appLogoCanvas} />
                </figure>

                <main className={appContent}>
                  {name && <h3 className={appTitle}>{name}</h3>}

                  {description && (
                    <div className={appDescription}>{description}</div>
                  )}
                </main>
              </a>
            ))}
          </main>
        )}
      </article>
    </section>
  )
}

export default Apps
