import { FC, useEffect } from 'react'

import useSignal from '$actions/useSignal'
import useReturnTo from '$actions/useReturnTo'
import useRouter from '$actions/useRouter'
import usePageClass from '$browser/usePageClass'

import AccountSelection from '$blocks/AccountSelection'

import { status } from '$store/status'
import { updateCurrent, session } from '$store/session'

import useAutoRequestLogin from '$actions/useAutoRequestLogin'

const pageName = 'Accounts'

/**
 * Authorized `session` listing page.
 *
 * After selecting one session, page will be redirected
 * to `apps` or `?return_to=...` route.
 *
 * On mount: This page will:
 * - Redirect to login when unauthorized. Otherwise displays a list of authenticated account.
 * - When url contain `?account-id=...` query param, it'll be validated and marked as `active` session.
 *
 * On session selection:
 * - Selected session will be marked as `active`.
 *
 * On add new:
 * - Redirects to `authLogin`(Login) page, also carries current page's URL query parameters.
 */
export const Accounts: FC = () => {
  usePageClass({ name: pageName })

  useAutoRequestLogin()

  const { redirectWithQueryParams, queryParams } = useRouter()
  const returnTo = useReturnTo()

  const { tokenStates } = useSignal(session)
  const { isActiveSessionAlive } = useSignal(status)

  const requestedAccountId = queryParams['account-id']
  const requestedAccountIsValid = !!tokenStates.find(
    ({ id, state }) => requestedAccountId == id && state?.alive
  )

  // Redirect when session is selected, favors `return_to` route
  useEffect(() => {
    if (!isActiveSessionAlive) return

    returnTo('apps', { replace: true })
  }, [isActiveSessionAlive, returnTo])

  // Auto-select `requestedAccountId` when session is `alive`
  useEffect(() => {
    if (!requestedAccountIsValid) return

    updateCurrent(requestedAccountId)
  }, [requestedAccountIsValid, requestedAccountId])

  return (
    <AccountSelection
      onSelect={updateCurrent}
      onAddNew={() => {
        redirectWithQueryParams('authLogin')
      }}
    />
  )
}

export default Accounts
