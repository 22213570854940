import { useState } from 'react'
import {
  propsWithClassNames,
  trueOrUndefined
} from '@genie-fintech/ui/functions'

import { Component } from './types'
import { button, container, main, nav, navInner } from './styles.css'

export const TabContents: Component = ({
  tabs = [],
  defaultTabId,
  children,
  containerProps
}) => {
  const [activeTab, setActiveTab] = useState(defaultTabId)

  const formattedTabs = tabs.map(({ id, ...rest }) => ({
    ...rest,
    id,
    active: id === activeTab
  }))

  return (
    <article {...propsWithClassNames(containerProps, container)}>
      <nav className={nav}>
        <div className={navInner}>
          {formattedTabs.map(({ id, active, name, buttonProps }) => (
            <button
              key={id}
              type="button"
              data-active={trueOrUndefined(active)}
              onClick={() => setActiveTab(id)}
              {...propsWithClassNames(buttonProps, button)}
            >
              {name}
            </button>
          ))}
        </div>
      </nav>

      <main className={main}>{children(activeTab)}</main>
    </article>
  )
}

export default TabContents
