import { NSInfo } from './types'
import { createNamespaces } from './functions'

const ICON_INFO: NSInfo = {
  viewBox: '0 0 24 24',
  width: 24,
  height: 24
}
const ICON_NAMESPACES = createNamespaces({
  Add: ICON_INFO,
  AddCircle: ICON_INFO,
  Adjustment: ICON_INFO,
  Attachment: ICON_INFO,
  Backward: ICON_INFO,
  Bank: ICON_INFO,
  Bar: ICON_INFO,
  Block: ICON_INFO,
  Briefcase: ICON_INFO,
  Building: ICON_INFO,
  Calender: ICON_INFO,
  Camera: ICON_INFO,
  Cancel: ICON_INFO,
  Chart: ICON_INFO,
  Chat: ICON_INFO,
  Condense: ICON_INFO,
  Copy: ICON_INFO,
  CopyDocuments: ICON_INFO,
  CorrectUser: ICON_INFO,
  Cross: ICON_INFO,
  CrossCircle: ICON_INFO,
  Dark: ICON_INFO,
  Database: ICON_INFO,
  Discussion: ICON_INFO,
  Document: ICON_INFO,
  DoubleTick: ICON_INFO,
  Down: ICON_INFO,
  Download: ICON_INFO,
  Draft: ICON_INFO,
  EditFile: ICON_INFO,
  EditPencil: ICON_INFO,
  Email: ICON_INFO,
  Expanded: ICON_INFO,
  EyeOff: ICON_INFO,
  EyeOn: ICON_INFO,
  File: ICON_INFO,
  FileListing: ICON_INFO,
  Filter: ICON_INFO,
  Flower: ICON_INFO,
  Folder: ICON_INFO,
  Forward: ICON_INFO,
  Image: ICON_INFO,
  Invoice: ICON_INFO,
  Leaf: ICON_INFO,
  Left: ICON_INFO,
  Light: ICON_INFO,
  Link: ICON_INFO,
  Loading: ICON_INFO,
  Lock: ICON_INFO,
  LogIn: ICON_INFO,
  LogOut: ICON_INFO,
  Lou: ICON_INFO,
  Menu: ICON_INFO,
  MoreHorizontal: ICON_INFO,
  MoreVertical: ICON_INFO,
  Notification: ICON_INFO,
  Percentage: ICON_INFO,
  Publish: ICON_INFO,
  QuestionCircle: ICON_INFO,
  Recycling: ICON_INFO,
  Redirect: ICON_INFO,
  Remark: ICON_INFO,
  Reset: ICON_INFO,
  Right: ICON_INFO,
  Save: ICON_INFO,
  Search: ICON_INFO,
  Send: ICON_INFO,
  Slip: ICON_INFO,
  System: ICON_INFO,
  Tag: ICON_INFO,
  TagDelete: ICON_INFO,
  Tick: ICON_INFO,
  TickCircle: ICON_INFO,
  Ticket: ICON_INFO,
  Timeline: ICON_INFO,
  Trash: ICON_INFO,
  TurnOff: ICON_INFO,
  TurnOn: ICON_INFO,
  Unlock: ICON_INFO,
  Unpublish: ICON_INFO,
  Up: ICON_INFO,
  UpDown: ICON_INFO,
  Upload: ICON_INFO,
  UploadFolder: ICON_INFO,
  User: ICON_INFO,
  Users: ICON_INFO,
  Warning: ICON_INFO
})

export const ALL_NAMESPACES = { ...ICON_NAMESPACES }
