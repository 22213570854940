import { getThemedData } from '$app/utilities'

import { carro, genie } from './mode.css'

export const themeClass = getThemedData({
  genie,
  carro
})

export default themeClass
