import { FC, useEffect } from 'react'

import usePageClass from '$browser/usePageClass'
import useSignal from '$actions/useSignal'
import queryParams from '$store/queryParams'
import useRouter from '$actions/useRouter'

const pageName = 'Root'

export const Root: FC = () => {
  usePageClass({ name: pageName })

  const { params, cached } = useSignal(queryParams)
  const { redirect, redirectWithReturnTo } = useRouter()

  // Redirect to `account selection` page when there's `client_id` present
  // Otherwise, redirect to `app` listing
  useEffect(() => {
    // Wait till url query parameters are cached
    if (!cached) return

    if (params.client_id) {
      redirectWithReturnTo('authAccounts', 'authApp')
    } else {
      redirect('apps')
    }
  }, [redirect, redirectWithReturnTo, params.client_id, cached])

  return null
}

export default Root
