import { FC } from 'react'

import useEmailLogin from '$actions/useEmailLogin'
import useRouter from '$actions/useRouter'

import LoginForm from '$hook-forms/LoginForm'
import MobileOTPRequestForm from '$hook-forms/MobileOTPRequestForm'
import useMobileOTPRequest from '$actions/useMobileOTPRequest'

import Link from '$elements/Link'
import TabContents from '$elements/TabContents'

import { container, forgotLink, methods, title } from './styles.css'

export const LoginBlock: FC = () => {
  const {
    queryParams: { method = 'email' }
  } = useRouter()

  const loginActions = useEmailLogin()
  const otpActions = useMobileOTPRequest()

  return (
    <section className={container}>
      <h2 className={title}>Log In</h2>

      <TabContents
        tabs={[
          { id: 'email', name: 'Email' },
          { id: 'mobile', name: 'Phone Number' }
        ]}
        defaultTabId={method}
        containerProps={{ className: methods }}
      >
        {id => (
          <>
            {id == 'email' && (
              <>
                <LoginForm {...loginActions} />
                <Link to="authForgotPassword" className={forgotLink}>
                  forgot password?
                </Link>
              </>
            )}
            {id == 'mobile' && <MobileOTPRequestForm {...otpActions} />}
          </>
        )}
      </TabContents>
    </section>
  )
}

export default LoginBlock
