import React from 'react'
import ReactDOM from 'react-dom/client'

import { init, ErrorBoundary, withProfiler, Replay } from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'

import CenteredLayout from '$layouts/CenteredLayout'

import App from '$app/App'

import '@genie-fintech/ui/style/global'
import '$styles/global'

init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [new BrowserTracing(), new Replay()],
  tracesSampleRate: import.meta.env.DEV ? 1 : 0.1,
  replaysSessionSampleRate: import.meta.env.DEV ? 1 : 0.1
})

const TrackedApp = withProfiler(App)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary
      showDialog
      fallback={
        <CenteredLayout>
          The application has stopped working. Please get in touch with the
          administrators.
        </CenteredLayout>
      }
    >
      <TrackedApp />
    </ErrorBoundary>
  </React.StrictMode>
)
