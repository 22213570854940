import { format } from 'date-fns'

import { getThemedData } from '$app/utilities'

export const authLayoutDescriptionText = (() => {
  const t = (text: string) => {
    return `To access the ${text} Service Dashboard, please log in to the service.`
  }

  return getThemedData({
    carro: t('Carro SSO'),
    genie: t('Genie Auth')
  })
})()

export const copyrightText = (() => {
  const t = (text: string) => {
    const year = format(new Date(), 'yyyy')

    return `&copy;${year} ${text} Service. All rights reserved.`
  }

  return getThemedData({
    carro: t('Carro SSO'),
    genie: t('Genie Auth')
  })
})()

export const loginFormPlaceholderText = (() => {
  const email = (text: string) => `E.g. user@${text}`

  return {
    email: getThemedData({
      carro: email('carro.co'),
      genie: email('geniefintech.com')
    })
  }
})()
