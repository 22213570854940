import { FC } from 'react'

import { AuthLogoProps } from './types'

const Carro: FC<AuthLogoProps> = ({ color = 'white', ...props }) => (
  <svg width={140} height={24} viewBox="0 0 140 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3213 7.60816C16.367 4.78739 14.0946 3.63684 10.4781 3.63684C7.70368 3.63684 5.09311 4.69687 3.12729 6.62165C1.11063 8.5962 0 11.2877 0 14.2007C0 19.7872 4.0909 23.9999 9.51573 23.9999C12.8933 23.9999 15.4095 22.9156 17.4346 20.587C17.5182 20.491 17.5588 20.3628 17.5461 20.2357C17.5332 20.1083 17.4678 19.9906 17.3663 19.9125L14.6903 17.86C14.4931 17.7082 14.2128 17.7396 14.0526 17.9314C12.9521 19.2484 11.4734 19.9159 9.65724 19.9159C6.77223 19.9159 4.67838 17.5241 4.67838 14.229C4.67838 12.4496 5.24227 10.8091 6.26607 9.60998C7.30626 8.39173 8.76193 7.72104 10.3649 7.72104C12.2269 7.72104 13.6236 8.47567 14.6352 10.0281C14.7024 10.1313 14.8053 10.2026 14.9251 10.2288C15.0471 10.2552 15.1718 10.2329 15.2763 10.1646L18.1931 8.26338C18.2976 8.19538 18.3712 8.08587 18.3953 7.96304C18.4193 7.84005 18.3924 7.71078 18.3213 7.60816"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.5319 13.4489C49.1272 13.4489 51.2105 11.8405 51.2105 8.28104C51.2105 5.23504 49.3622 4.07142 46.1367 4.07142H45.5858C45.4769 4.07142 45.3836 4.1513 45.3649 4.26061L43.831 13.1809C43.8069 13.3207 43.9125 13.4489 44.0519 13.4489H45.5319ZM53.996 23.2721H49.6651C49.582 23.2721 49.5057 23.2253 49.4668 23.1505L46.5749 17.5865C46.533 17.506 46.4486 17.4604 46.3595 17.467C46.0717 17.4882 45.7803 17.4882 45.4645 17.4882H43.3006C43.1918 17.4882 43.0987 17.5678 43.0797 17.6769L42.1373 23.0835C42.1183 23.1925 42.0252 23.2721 41.9164 23.2721H37.7685C37.629 23.2721 37.5232 23.1438 37.5474 23.0038L41.5003 0.188879C41.5193 0.0795763 41.6124 0 41.7213 0H46.6068C52.4871 0 55.9478 3.04555 55.9478 8.28204C55.9478 11.6291 54.4375 14.8125 50.9763 16.1997C50.8516 16.2496 50.7946 16.3964 50.8573 16.517L54.1943 22.9366C54.2735 23.0889 54.1651 23.2721 53.996 23.2721V23.2721Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.7849 9.74085C68.7849 12.6727 67.0542 14.1591 63.6409 14.1591H62.2507C62.2443 14.1591 62.2392 14.1569 62.2349 14.152C62.2309 14.1473 62.2293 14.1418 62.2306 14.1357L63.6714 6.19242C63.6731 6.1829 63.6817 6.17606 63.6917 6.17606H64.2091C67.3309 6.17606 68.7849 7.30873 68.7849 9.74085V9.74085ZM68.8116 16.9875C68.8099 16.9843 68.8085 16.9808 68.8106 16.9751C68.812 16.9716 68.8157 16.9652 68.825 16.9616C71.8695 15.805 73.6155 13.1728 73.6155 9.74036C73.6155 7.40032 72.792 5.47266 71.2341 4.16578C69.6868 2.86798 67.4105 2.18188 64.6508 2.18188H60.0618C59.8671 2.18188 59.701 2.31637 59.667 2.50172L55.9538 22.8178C55.9333 22.931 55.9654 23.0461 56.0416 23.1339C56.118 23.2218 56.2299 23.2722 56.3487 23.2722H60.245C60.4398 23.2722 60.6058 23.1381 60.6397 22.9527L61.5248 18.1383C61.5267 18.1289 61.5352 18.1219 61.5452 18.1219H63.5779C63.8747 18.1219 64.1552 18.1219 64.4324 18.1024C64.4359 18.1023 64.4478 18.1021 64.4529 18.1117L67.1694 23.0664C67.2392 23.1934 67.3747 23.2722 67.5237 23.2722H71.5918C71.7337 23.2722 71.8621 23.2022 71.9352 23.0848C72.0086 22.9674 72.0126 22.8251 71.9462 22.704L68.8116 16.9875Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.9753 7.72629C88.129 7.72629 90.248 10.01 90.248 13.4091C90.248 17.3599 87.9291 19.9128 84.3402 19.9128C81.1863 19.9128 79.067 17.6289 79.067 14.23C79.067 10.279 81.3861 7.72629 84.9753 7.72629M92.1178 6.40873C90.3036 4.62115 87.8078 3.63684 85.0902 3.63684C82.1969 3.63684 79.5191 4.64401 77.5505 6.47283C75.4577 8.41705 74.3516 11.0695 74.3516 14.144C74.3516 16.906 75.3571 19.4216 77.1825 21.2277C78.9894 23.0153 81.4801 23.9999 84.1957 23.9999C87.0911 23.9999 89.7739 22.9929 91.7498 21.1643C93.8526 19.2185 94.9639 16.566 94.9639 13.4932C94.9639 10.7329 93.9532 8.21685 92.1178 6.40873"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.804 17.2276L29.3985 9.60923L30.6104 17.2276H25.804ZM36.7958 22.8411L31.852 2.46677C31.8115 2.29986 31.6581 2.18188 31.4815 2.18188H29.1717C29.0308 2.18188 28.9013 2.25746 28.8353 2.37841L17.7186 22.7529C17.653 22.8584 17.651 22.9908 17.7134 23.0982C17.7757 23.2056 17.8935 23.2722 18.0205 23.2722H21.8979C22.0223 23.2722 22.1373 23.2087 22.2009 23.105L23.5601 20.8865H31.5769L32.1313 23.0137C32.171 23.1657 32.3119 23.2722 32.4734 23.2722H36.4403C36.5374 23.2722 36.6345 23.2404 36.7031 23.1736C36.7947 23.0842 36.8285 22.9595 36.7958 22.8411V22.8411Z"
      fill={color}
    />
    <path
      d="M132.825 24C131.532 24 130.366 23.7147 129.326 23.1441C128.302 22.5566 127.488 21.7343 126.884 20.6769C126.296 19.6028 126.002 18.3524 126.002 16.9259C126.002 15.4993 126.305 14.2573 126.909 13.2C127.513 12.1259 128.335 11.3035 129.376 10.7329C130.416 10.1455 131.583 9.85175 132.875 9.85175C134.167 9.85175 135.334 10.1455 136.374 10.7329C137.432 11.3035 138.254 12.1259 138.842 13.2C139.446 14.2573 139.748 15.4993 139.748 16.9259C139.748 18.3357 139.446 19.5776 138.842 20.6517C138.237 21.7259 137.407 22.5566 136.349 23.1441C135.292 23.7147 134.117 24 132.825 24ZM132.825 22.4643C133.731 22.4643 134.57 22.2629 135.342 21.8601C136.114 21.4406 136.735 20.8196 137.205 19.9972C137.692 19.158 137.935 18.1343 137.935 16.9259C137.935 15.7175 137.7 14.7021 137.23 13.8797C136.76 13.0406 136.139 12.4196 135.367 12.0168C134.595 11.5972 133.756 11.3874 132.85 11.3874C131.944 11.3874 131.105 11.5972 130.332 12.0168C129.56 12.4196 128.939 13.0406 128.47 13.8797C128.016 14.7021 127.79 15.7175 127.79 16.9259C127.79 18.1343 128.016 19.158 128.47 19.9972C128.939 20.8196 129.552 21.4406 130.307 21.8601C131.079 22.2629 131.919 22.4643 132.825 22.4643Z"
      fill={color}
    />
    <path
      d="M118.708 24C117.13 24 115.838 23.6392 114.831 22.9175C113.84 22.179 113.287 21.1804 113.169 19.9217H114.982C115.066 20.6937 115.427 21.3231 116.064 21.8098C116.719 22.2797 117.592 22.5147 118.682 22.5147C119.639 22.5147 120.386 22.2881 120.923 21.835C121.477 21.3818 121.754 20.8196 121.754 20.1483C121.754 19.6783 121.603 19.2923 121.301 18.9902C120.999 18.6881 120.613 18.4531 120.143 18.2853C119.689 18.1007 119.068 17.9077 118.28 17.7063C117.256 17.4378 116.425 17.1692 115.787 16.9007C115.15 16.6322 114.604 16.2378 114.151 15.7175C113.715 15.1804 113.496 14.4671 113.496 13.5776C113.496 12.9063 113.698 12.2853 114.101 11.7147C114.503 11.1441 115.074 10.6909 115.813 10.3552C116.551 10.0196 117.39 9.85175 118.33 9.85175C119.807 9.85175 120.999 10.2294 121.905 10.9846C122.811 11.7231 123.298 12.7469 123.365 14.0559H121.603C121.552 13.2504 121.234 12.6042 120.646 12.1175C120.075 11.614 119.287 11.3622 118.28 11.3622C117.39 11.3622 116.668 11.572 116.115 11.9916C115.561 12.4112 115.284 12.9315 115.284 13.5524C115.284 14.0895 115.443 14.5343 115.762 14.8867C116.098 15.2224 116.509 15.4909 116.996 15.6923C117.482 15.8769 118.137 16.0867 118.959 16.3217C119.95 16.5902 120.738 16.8503 121.326 17.1021C121.913 17.3538 122.417 17.7231 122.836 18.2098C123.256 18.6965 123.474 19.3427 123.491 20.1483C123.491 20.8867 123.289 21.5497 122.887 22.1371C122.484 22.7077 121.922 23.1608 121.2 23.4965C120.478 23.8322 119.647 24 118.708 24Z"
      fill={color}
    />
    <path
      d="M104.905 23.9748C103.747 23.9748 102.723 23.7734 101.834 23.3706C100.961 22.9678 100.273 22.4056 99.7695 21.6839C99.266 20.9622 98.9974 20.1566 98.9639 19.2671H100.827C100.911 20.1231 101.288 20.8783 101.96 21.5329C102.631 22.1706 103.613 22.4895 104.905 22.4895C106.097 22.4895 107.037 22.1874 107.725 21.5832C108.43 20.9622 108.782 20.1818 108.782 19.242C108.782 18.4867 108.589 17.8825 108.203 17.4294C107.817 16.9594 107.339 16.6154 106.768 16.3972C106.197 16.1622 105.409 15.9105 104.402 15.642C103.227 15.3231 102.295 15.0126 101.607 14.7105C100.919 14.4084 100.332 13.9385 99.845 13.3007C99.3583 12.6629 99.1149 11.7986 99.1149 10.7077C99.1149 9.8014 99.3499 8.9958 99.8198 8.29091C100.29 7.56923 100.953 7.00699 101.809 6.6042C102.665 6.2014 103.646 6 104.754 6C106.382 6 107.691 6.4028 108.681 7.20839C109.688 7.9972 110.267 9.02098 110.418 10.2797H108.505C108.388 9.55804 107.993 8.92028 107.322 8.36643C106.651 7.7958 105.744 7.51049 104.603 7.51049C103.546 7.51049 102.665 7.7958 101.96 8.36643C101.255 8.92028 100.902 9.68391 100.902 10.6573C100.902 11.3958 101.095 11.9916 101.481 12.4448C101.867 12.8979 102.346 13.242 102.916 13.4769C103.504 13.7119 104.293 13.9636 105.283 14.2322C106.424 14.551 107.347 14.8699 108.052 15.1888C108.757 15.4909 109.353 15.9608 109.839 16.5986C110.326 17.2364 110.569 18.0923 110.569 19.1664C110.569 19.9888 110.351 20.7692 109.915 21.5077C109.479 22.2462 108.832 22.842 107.976 23.2951C107.121 23.7482 106.097 23.9748 104.905 23.9748Z"
      fill={color}
    />
  </svg>
)

export default Carro
